// hello_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ]

  copy_message() {
    this.messageTarget.select(); 
    this.messageTarget.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
  }
}
